<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="選んでください" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h2>「UniLive」出店プロトコル</h2>
		<p>『UniLive出店プロトコル』は、Pix Technology PTE. LTD.（以下「味方」と略称する）と貴方（貴方は自然人、法人又はその他の組織）が約束した双方の権利と義務を規範化する法的効力を有する電子協議、以下「本協議」と称する。あなた方は「同意します」をチェックするか、「私はこの契約を読んで遵守しています」ボタンをクリックします。つまり、あなた方は本契約のすべての条項をよく読んで、十分に理解して、完全に保留せずに受け入れていることを示します。
		</p>
		<h3>第1条合意の目的</h3>
		<p>
			本契約は、事業者とUniLiveプラットフォームとの間の権利と義務を明確にし、事業者の経営行為を規範化し、消費者権益を維持し、UniLiveプラットフォームの健全な発展を促進し、取引における各当事者の合法的権益を確保することを目的とする。
		</p>
		<h3>第2条定義</h3>
		<p>1、UniLive：本アプリケーション及びその関連サービスの運営者を指し、オンライン取引及びサービスサポートを提供するプラットフォーム。</p>
		<p>
			2、事業者：個人事業主、会社、その他の法人エンティティを含むが、これらに限定されない、UniLiveに登録し、商業活動を展開する個人または企業を指す。
		</p>
		<p>
			3、消費者：UniLiveで商品やサービスを購入するユーザーを指す。
		</p>
		<h3>第3条事業者の入居</h3>
		<p>
			1、登録要求：業者はUniLiveに真実、正確な個人または企業情報を記入しなければならない、名前、連絡先、営業許可証、税務登録証などを含む。事業者は登録情報のタイムリーな更新を確保しなければならない。情報が変化する場合は、48時間以内に更新しなければならない。
		</p>
		<p>2、審査プロセス：UniLiveは業者から提出された情報を審査し、審査時間は通常3～5営業日である。審査が通過すると、業者は確認通知を受け、開店機能が正式に有効になる。
		</p>
		<p>3、店舗設置：店舗名、ロゴ、プロフィール、商品分類などを含む店舗をUniLiveに設置し、情報がプラットフォームの規定に合致することを確保する必要がある。
		</p>
		<h3>第4条事業者の権利と義務</h3>
		<p>
			1、UniLiveで商品情報を発表して販売し、プラットフォームが提供する各種サービスを享受する。
		</p>
		<p>2、UniLiveのキャンペーンやマーケティングに参加し、露出率を高める。</p>
		<p>3、販売データ、ユーザーフィードバック、市場分析レポートを取得して、経営戦略を最適化します。</p>
		<p>4、商品コンプライアンス：販売されている商品の合法性、真実性、コンプライアンスを確保し、偽物・不良品を販売してはならない。</p>
		<p>5、情報更新：定期的に商品情報を更新し、価格、在庫、配送情報の正確さ、明確さを確保する。</p>
		<p>6、プラットフォーム規則の遵守：UniLiveの各管理規定と操作規範を遵守し、プラットフォームトレーニングに参加し、経営能力を向上させる。</p>
		<h3>第5条UniLiveの権利と義務</h3>
		<p>1、事業者が発表した情報を監査し、管理し、情報のコンプライアンスを確保する。</p>
		<p>2、法律上の訴訟に至るまで、警告、一時停止、または店舗を含むがこれらに限定されない、契約に違反した事業者に対する対応策を講じる。</p>
		<p>3、市場状況と法律法規の変化に応じて、定期的にプラットフォームポリシーを更新し、業者はタイムリーに理解し、遵守しなければならない。</p>
		<p>4、安全で安定した取引プラットフォームを提供し、業者と消費者の取引の安全を保障し、詐欺やその他の違法行為を防止する。</p>
		<p>5、ビジネスの問題やフィードバックをタイムリーに処理し、必要な技術サポートとトレーニングを提供します。</p>
		<p>6、定期的に事業者に経営データ分析、市場動向報告及び普及提案を提供し、事業者の業績向上を支援する。</p>
		<h3>第6条違約責任</h3>
		<p>
			1、業者が本契約のいかなる条項に違反した場合、UniLiveは業者に改善を要求する権利があり、情状の軽重に応じて相応の処罰措置をとる権利があり、店舗の等級を下げる、商品の棚上げを制限する、サービスを終了するなどを含むが、それに限らない。
		</p>
		<p>2、業者の原因による消費者の苦情については、業者は相応の法的責任と賠償責任を負うべきである。</p>
		<p>3、UniLiveが合意通りにサービスを提供できなかった場合、業者はUniLiveに損害賠償を要求することができ、具体的な賠償金額は双方が協議して確定する。</p>
		<h3>第7条プロトコルの変更と解除</h3>
		<p>1、本契約書の修正または変更は双方が協議して一致しなければ解除できない。</p>
		<p>2、uniLiveは法律法規、市場変化などの要素に基づいて定期的に協定を改訂し、速やかに業者に通知する権利がある。</p>
		<p>3、事業者が本契約の解除を希望する場合は、7日前に書面でUniLiveに通知し、解除前に未完了の注文や消費者からの苦情を適切に処理しなければならない。</p>
		<p>4、UniLiveは、事業者が重大な違約行為を行っていることを発見した場合、直ちに本契約を解除する権利があり、事前に通知する必要はありません。</p>
		<h3>第8条その他の条項</h3>
		<p>1、秘密保持条項：双方は合意履行過程で知った商業秘密及びその他の情報の秘密保持に対応し、第三者に開示してはならない。
		</p>
		<p>2、不可抗力：不可抗力要素（例えば自然災害、戦争、政策変化など）により合意を履行できなくなった場合、双方は違約責任を負わない。
		</p>
	</div>
</template>

<script>
export default {
	data() {
		return {
			options: [
				{
					value: '中文',
					label: '中文'
				}, {
					value: 'English',
					label: 'English'
				}, {
					value: '한국인',
					label: '한국인'
				}, {
					value: '日本語',
					label: '日本語'
				}, {
					value: 'Русский',
					label: 'Русский'//俄国
				}, {
					value: 'Tiếng Việt',
					label: 'Tiếng Việt'//越南
				}, {
					value: 'ภาษาไทย',
					label: 'ภาษาไทย'//泰国
				}
			],
			value: '',
			language: ''
		}
	},
	created() {
		// url获取参数
		this.language = this.$route.query.language;
		if (this.language == 'zh_CN') {
			this.$router.push({
				path: '/StoreOpeningAgreementZh'
			})
		}
		if (this.language == 'en_US') {
			this.$router.push({
				path: '/StoreOpeningAgreementEn'
			})
		}
		if (this.language == 'ko_KR') {
			this.$router.push({
				path: '/StoreOpeningAgreementKo'
			})
		}
		if (this.language == 'ja_JP') {
			this.$router.push({
				path: '/StoreOpeningAgreementJa'
			})
		}
		//俄语
		if (this.language == 'ru_RU') {
			this.$router.push({
				path: '/StoreOpeningAgreementRu'
			})
		}
		//越南语
		if (this.language == 'vi_VN') {
			this.$router.push({
				path: '/StoreOpeningAgreementVi'
			})
		}
		//泰语
		if (this.language == 'th_TH') {
			this.$router.push({
				path: '/StoreOpeningAgreementTh'
			})
		}
	},
	methods: {
		clickChange(value) {
			this.value = value;
			if (value == '中文') {
				this.$router.push({
					path: '/StoreOpeningAgreementZh'
				})
			}
			if (value == 'English') {
				this.$router.push({
					path: '/StoreOpeningAgreementEn'
				})
			}
			if (value == '한국인') {
				this.$router.push({
					path: '/StoreOpeningAgreementKo'
				})
			}
			if (value == '日本語') {
				this.$router.push({
					path: '/StoreOpeningAgreementJa'
				})
			}
			if (value == 'Русский') {
				this.$router.push({
					path: '/StoreOpeningAgreementRu'
				})
			}
			if (value == 'Tiếng Việt') {
				this.$router.push({
					path: '/StoreOpeningAgreementVi'
				})
			}
			if (value == 'ภาษาไทย') {
				this.$router.push({
					path: '/StoreOpeningAgreementTh'
				})
			}
		},
	}
}
</script>

<style>
.container {
	padding: 0.1rem 0.3rem;
}

.lang {
	text-align: right;
	margin-bottom: 0.3rem;
}

h1,
h2 {
	color: #333;
	font-size: 0.5rem;
}

h3 {
	margin-top: 20px;
	font-size: 0.4rem;
}

p {
	margin-bottom: 15px;
	font-size: 0.3rem;
}

li {
	font-size: 0.3rem;
}
</style>